<template>
  <VueFinalModal
      content-transition="vfm-slide-left"
      :click-to-close="false"
      class="flex justify-start items-center"
      content-class="flex flex-col bg-white rounded-2xl w-[600px] h-[calc(100%-20px)] ml-[370px] 2xl:ml-[370px]"
  >
    <!-- Header -->
    <header class="w-full min-h-[50px] border-b border-gray-200 bg-[#fff8f2] rounded-t-2xl flex justify-between items-center px-4">
      <h1 class="text-[#3E546C] font-normal">Create Podcast</h1>
      <button @click="confirm" class="rounded-lg">
        <span class="pr pr-add text-[#3E546C] hover:text-[#000000]"></span>
      </button>
    </header>

    <!-- Content Section -->
    <section class="flex-grow flex flex-col p-10 gap-5">
      <!-- Podcast Title -->
      <div class="flex flex-col gap-2">
        <label for="title" class="text-sm font-medium text-gray-700">Title</label>
        <InputText id="title" v-model="podcast.title" type="text" placeholder="Enter podcast title" class="mt-1" />
      </div>

      <!-- Author ID -->
      <div class="flex flex-col gap-2">
        <label for="author" class="text-sm font-medium text-gray-700">Author</label>
        <InputText id="author" v-model="podcast.author_id" type="text" placeholder="Enter author ID" class="mt-1" />
      </div>

      <!-- Podcast Image Upload -->
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium text-gray-700">Podcast Image</label>
        <div
            ref="dropZoneImageRef"
            @click="triggerImageFileInput"
            class="w-full h-60 dropzone cursor-pointer border-2 border-dashed border-gray-300 rounded-md p-5 flex justify-center items-center relative"
            :class="{ 'bg-blue-100 border-blue-300': isOverImageDropZone }"
        >
          <input type="file" ref="imageFileInputRef" @change="handleImageFileChange" accept="image/*" class="hidden" />
          <img
              v-if="podcast.image"
              :src="podcast.image"
              alt="Uploaded Image"
              class="object-cover rounded-md absolute inset-0 w-full h-full"
          />
          <div
              v-if="podcast.image"
              class="rounded-md absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white"
          >
            Click or drop an image here to upload
          </div>
          <div v-else class="rounded-md text-center text-sm text-gray-500">
            Click or drop an image here to upload
          </div>
        </div>
      </div>

      <!-- MP3 File Upload -->
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium text-gray-700">Podcast MP3 File</label>
        <div
            ref="dropZoneAudioRef"
            @click="triggerAudioFileInput"
            class="w-full h-24 dropzone cursor-pointer border-2 border-dashed border-gray-300 rounded-md p-5 flex justify-center items-center relative"
            :class="{ 'bg-blue-100 border-blue-300': isOverAudioDropZone }"
        >
          <input type="file" ref="audioFileInputRef" @change="handleAudioFileChange" accept="audio/mp3" class="hidden" />
          <div v-if="podcast.audio" class="w-full">
            <!-- Audio Player -->
            <audio controls :src="podcast.audioUrl" class="w-full">
              Your browser does not support the audio element.
            </audio>
            <div
                class="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white"
                @click.stop="triggerAudioFileInput"
            >
              Click or drop an MP3 file here to change
            </div>
          </div>
          <div v-else class="rounded-md text-center text-sm text-gray-500">
            Click or drop an MP3 file here to upload
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="w-full min-h-[50px] border-t border-gray-200 bg-[#fff8f2] rounded-b-2xl flex justify-end items-center px-4">
      <button
          :disabled="!podcast.title || !selectedImageFile || !selectedAudioFile"
          @click="save"
          class="bg-[#3e546c] hover:bg-[#0D2B4B] px-3 py-1 rounded-md text-white text-xs disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Save
      </button>
    </footer>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import InputText from 'primevue/inputtext';
import { useDropZone } from '@vueuse/core';
import { usePodcastStore } from '@/store/podcasts/index.js';
import {usePagesStore} from "@/store/pages/index.js";

const emit = defineEmits(['confirm', 'created', 'close']);

// Refs for image upload
const dropZoneImageRef = ref(null);
const imageFileInputRef = ref(null);

// Refs for audio upload
const dropZoneAudioRef = ref(null);
const audioFileInputRef = ref(null);

const podcast = ref({
  title: '',
  author_id: null,
  image: null, // For storing the image preview URL
  audio: null, // For storing the audio file object
  audioUrl: null, // For storing the audio preview URL
});

const selectedImageFile = ref(null);
const selectedAudioFile = ref(null);

const podcastStore = usePodcastStore();
const pagesStore = usePagesStore();

/// IMAGE UPLOAD HANDLERS ///

// DropZone setup for Image
const { isOverDropZone: isOverImageDropZone } = useDropZone(dropZoneImageRef, {
  onDrop: (files) => {
    handleImageFiles(files);
  },
  onDragEnter: () => {},
  onDragLeave: () => {},
  dataTransferTypes: ['Files'],
  preventDefault: true,
});

// Function to trigger image file input for uploads
const triggerImageFileInput = () => {
  imageFileInputRef.value.click();
};

// Handle image file input change for manual uploads
const handleImageFileChange = (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    handleImageFiles(files);
  }
};

// Handle image files
const handleImageFiles = (files) => {
  if (files && files[0]) {
    const file = files[0];
    if (/^image\//.test(file.type)) {
      podcast.value.image = URL.createObjectURL(file); // Generate and store the image preview URL
      selectedImageFile.value = file;
    }
  }
};

/// AUDIO UPLOAD HANDLERS ///

// DropZone setup for Audio
const { isOverDropZone: isOverAudioDropZone } = useDropZone(dropZoneAudioRef, {
  onDrop: (files) => {
    handleAudioFiles(files);
  },
  onDragEnter: () => {},
  onDragLeave: () => {},
  dataTransferTypes: ['Files'],
  preventDefault: true,
});

// Function to trigger audio file input for uploads
const triggerAudioFileInput = () => {
  audioFileInputRef.value.click();
};

// Handle audio file input change for manual uploads
const handleAudioFileChange = (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    handleAudioFiles(files);
  }
};

// Handle audio files
const handleAudioFiles = (files) => {
  if (files && files[0]) {
    const file = files[0];
    if (file.type === 'audio/mp3' || file.name.endsWith('.mp3')) {
      podcast.value.audio = file;
      podcast.value.audioUrl = URL.createObjectURL(file); // Generate and store the audio preview URL
      selectedAudioFile.value = file;
    } else {
      alert('Please upload an MP3 file.');
    }
  }
};

// Watch for changes in the podcast title, image, or audio to enable/disable the save button
watch([() => podcast.value.title, () => selectedImageFile.value, () => selectedAudioFile.value], () => {
  // Additional validations can be performed here if needed
});

// Confirm action
const confirm = () => {
  emit('confirm', {});
};

// Save action
const save = async () => {
  if (!podcast.value.title || !selectedImageFile.value || !selectedAudioFile.value) {
    alert('Please provide a title, select an image, and upload an MP3 file.');
    return;
  }

  try {
    // Create the podcast without files
    const podcastData = {
      title: podcast.value.title,
      author_id: podcast.value.author_id,
      // Include other podcast fields if necessary
    };

    const newPodcast = await podcastStore.addPodcast(podcastData);

    // Now upload the image file
    await podcastStore.uploadPodcastImage(newPodcast.id, selectedImageFile.value);

    // Now upload the MP3 file
    await podcastStore.uploadPodcastMp3(newPodcast.id, selectedAudioFile.value);

    await pagesStore.attachPodcastToPage(pagesStore.selectedPage.id, newPodcast.id)

    // Emit events and reset form
    emit('created');
    emit('close');
    // Reset form data
    podcast.value.title = '';
    podcast.value.author_id = null;
    podcast.value.image = null;
    podcast.value.audio = null;
    podcast.value.audioUrl = null;
    selectedImageFile.value = null;
    selectedAudioFile.value = null;
  } catch (error) {
    console.error('Error saving podcast:', error);
    alert('Failed to save podcast. Please try again.');
    // Handle error (e.g., show error message)
  }
};
</script>

<style scoped>
.dropzone {
  transition: background-color 0.3s, border-color 0.3s;
}
</style>
