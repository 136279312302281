<template>
    <registration />
  </template>
  
  <script>
  import Registration from "@/components/app/user/Registration.vue"
  
  export default {
    components: {
      Registration
    }
  }
  </script>
  
  <style scoped>
  </style>