<template>
    <div class="h-80px mx-10">
        <Line :data="data" :options="options" />
    </div>
  </template>

  <script setup>
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js'
  import { Line } from 'vue-chartjs'

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const props = defineProps({
    data: {
      type: Object,
      default: {
        labels: ['Novi Pazar', 'Bijelo Polje', 'Tutin', 'Beograd', 'Bužim', 'Cazin', 'Prizren', 'Rožaje', 'Brčko', 'Zenica', 'Sjenica'],
        datasets: [
          {
            label: false,
            borderColor: '#EBA36C',
            data: [294, 166, 117, 172, 3, 6, 507, 1, 10, 79, 6]
          }
        ]
      }
    },
    options: {
      type: Object,
      default: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    }
  })

  </script>
