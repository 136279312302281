<template>
    <div class="flex items-center justify-center bg-radial-gradient dark:bg-dark-radial-gradient w-full overflow-hidden relative lg:pb-0 xl:pb-[320px] 4xl:pb-[100px] pb-0">
        <PrayerHomeBigDark class="absolute xs:hidden sm:hidden md:hidden lg:block lg:w-[1300px] lg:left-[-60px] lg:top-[-70px] xl:w-[2300px] xl:left-[-240px] xl:top-[-380px] 4xl:w-[4800px] 4xl:left-0 4xl:top-[-850px]" v-if="isDarkMode" ></PrayerHomeBigDark>
        <PrayerHomeBig class="absolute xs:hidden sm:hidden md:hidden lg:block lg:w-[1300px] lg:left-[-60px] lg:top-[-70px] xl:w-[2300px] xl:left-[-240px] xl:top-[-380px] 4xl:w-[4800px] 4xl:left-0 4xl:top-[-850px]" v-else ></PrayerHomeBig>
        <div class="container overflow-visible h-screen">
            <nav-elements />
            <hero title="Namaska vremena"/>
        </div>
    </div>
</template>

<script setup>
import Hero from '@/components/app/Hero.vue'
import NavElements from '@/components/app/NavElements.vue'
import { useDark } from '@vueuse/core';
import PrayerHomeBig from "@/assets/prayer-home-big.svg?component";
import PrayerHomeBigDark from "@/assets/prayer-home-big-dark.svg?component";

const isDarkMode = useDark();
</script>

<style scoped>
.container {
  position: relative;
  z-index: 1; /* This ensures that your content appears on top of the background */
}
</style>


