<template>
    <div class="flex-1 dashboard-card-bg text-white rounded-md gap-4 p-4 flex flex-col">
        <div class="flex flex-row justify-between">
            <div class="flex gap-2 items-center">
                <div class="bg-[#519F95] rounded-md px-2">
                    <i class="pr pr-like-2 text-white"></i>
                </div>
                <h3 class="text-[12px] leading-[14.52px]">{{ header }}</h3>
            </div>
            <div class="">
                <i class="pr pr-more text-white w-[12px]"></i>
            </div>
        </div>
        <div class="flex gap-3 items-center">
            <h2 class="text-[24px] font-semibold">{{ number }}</h2>
            <span class="flex justify-center items-center text-[10px] p-1 bg-[#519F95] rounded-md h-4">{{ number2 }}</span>
        </div>
        <div class="w-full">
          <p class="text-[12px]">{{ text }}</p>
        </div>

        <div class="flex flex-row gap-3 items-center">

            <div class="rounded-xl bg-[#1A4747] h-2 w-full ">
                <div class="rounded-xl bg-white h-2 w-8"></div>
            </div>
            <div>
                <p class="text-[8px] leading-[9.68px] w-max">{{ day }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: String,
        number: String,
        text: String,
        number2: String,
        day: String
    }
}
</script>

<style scoped>

.dashboard-card-bg {
    background: linear-gradient(90deg, rgba(29,72,73,1) 0%, rgba(40,160,136,1) 100%);
}
</style>


