<template>
    <div class="flex flex-col h-[58px]">
        <div class="text-end my-4 mr-6">
            <h3 class="text-[12px] leading-[14.06px] text-[#1A534E]">Eldin Mujović</h3>
            <p class="text-[10px] leading-[11.72px] text-[#A3A3A3]">Pisac</p>
        </div>
        <div> 
            <div class="image"></div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
.image {
    /* image 2 */

width: 30px;
height: 30px;

background: #C4C4C4;
border: 0.741176px solid rgba(62, 84, 108, 0.35);
border-radius: 24.1818px;

/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

}
</style>
