import authenticated from '@/middleware/authenticated';
import Page from '@/components/app/dashboard/Page.vue';
import Book from "@/pages/Book.vue";

export default [
    {
        path: '/books/:bookId',
        name: 'library.books',
        component: Book,
        meta: { middleware: [authenticated] },
        children: [
            {
                path: 'pages/:pageId',
                name: 'library.books.pages',
                component: Page,
                meta: { middleware: [authenticated] },
            }
        ]
    }
];
