<template>
  <div
      ref="dropZoneRef"
      @click="triggerFileInput"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
      :class="[
      'w-full h-[500px] dropzone cursor-pointer border-2 border-dashed rounded-md p-5 flex justify-center items-center relative transition-colors duration-300',
      isOverDropZone ? 'bg-blue-100 border-blue-300' : 'border-gray-300'
    ]"
  >
    <input
        type="file"
        ref="fileInputRef"
        @change="handleFileChange"
        :accept="accept"
        class="hidden"
    />

    <!-- Display the uploaded image -->
    <img
        v-if="imageUrl"
        :src="imageUrl"
        alt="Uploaded Image"
        class="object-cover rounded-md absolute inset-0 w-full h-full"
    />

    <!-- Overlay text when image is present -->
    <div
        v-if="imageUrl"
        class="rounded-md absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white"
    >
      Click or drop another image to replace
    </div>

    <!-- Placeholder text when no image is present -->
    <div v-else class="text-center text-sm text-gray-500">
      Click or drop an image here to upload
    </div>

    <!-- Loading Indicator -->
    <div v-if="isUploading" class="absolute">
      <h2>ECHO</h2>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import {usePageContentsStore} from "@/store/page-contents/index.js";

const props = defineProps({
  modelValue: {
    type: [String, File],
    default: '',
  },
  uploadId: {
    type: [String, Number],
    required: true,
  },
  accept: {
    type: String,
    default: 'image/*',
  },
});

const pagesContentsStore = usePageContentsStore()

const emit = defineEmits(['update:modelValue', 'upload-error']);

const dropZoneRef = ref(null);
const fileInputRef = ref(null);

const isOverDropZone = ref(false);
const isUploading = ref(false);
const imageUrl = ref(props.modelValue);

const triggerFileInput = () => {
  fileInputRef.value.click();
};

const handleDragOver = () => {
  isOverDropZone.value = true;
};

const handleDragLeave = () => {
  isOverDropZone.value = false;
};

const handleDrop = (event) => {
  isOverDropZone.value = false;
  const files = event.dataTransfer.files;
  if (files && files.length > 0) {
    uploadFile(files[0]);
  }
};

const handleFileChange = (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    uploadFile(files[0]);
  }
};

// Upload file to the server
const uploadFile = async (file) => {
  if (!/^image\//.test(file.type)) {
    alert('Please upload a valid image file.');
    return;
  }

  isUploading.value = true;

  try {
    const formData = new FormData();
    formData.append('file', file);
    pagesContentsStore.deleteFiles(props.uploadId).then((response) => {
      pagesContentsStore.uploadFile(formData, props.uploadId).then((response) => {
        emit('update:modelValue', response[0].url);
      })
    })



  } catch (error) {
    emit('upload-error', error);
    alert('Failed to upload image. Please try again.');
  } finally {
    isUploading.value = false;
  }
};

// Watch for external changes to modelValue
watch(
    () => props.modelValue,
    (newVal) => {
      imageUrl.value = newVal;
    }
);
</script>
