import axiosInstance from "@/plugins/axios";

export const actions = {
    putNewItem(item) {
        this.addPageContent(item);
    },
    fetchPageContents(pageId) {
        return axiosInstance.get(`/api/page-contents?page_id=${pageId}`)
            .then(response => {
                this.pageContents = response.data.data;
            })
            .catch(error => {
                console.error('Error fetching page contents:', error);
                throw error; // Rethrow for further handling
            });
    },
    fetchPageContent(pageId, id) {
        return axiosInstance.get(`/api/page-contents/${id}?page_id=${pageId}`)
            .then(response => {
                this.selectedPageContent = response.data.data;
            })
            .catch(error => {
                console.error('Error fetching page contents:', error);
                throw error; // Rethrow for further handling
            });
    },
    async uploadFile(data, id) {
        try {
            const response = await axiosInstance.post(`/api/page-contents/${id}/files`, data);
            return response.data.data; // Return the added page data
        } catch (error) {
            console.error('Error adding page:', error);
            throw error;
        }
    },
    async deleteFiles(id) {
        try {
            const response = await axiosInstance.delete(`/api/page-contents/${id}/files`);
            return response.data.data; // Return the added page data
        } catch (error) {
            console.error('Error adding page:', error);
            throw error;
        }
    },
    addPageContent(pageContentData) {
        return axiosInstance.post('/api/page-contents', pageContentData)
            .then(response => {
                this.pageContents.push(response.data.data);
            })
            .catch(error => {
                console.error('Error adding page content:', error);
                throw error;
            });
    },
    updatePageContent(id, pageContentData) {
        console.log('Sending updatePageContent request for id:', id, 'data:', pageContentData);
        return axiosInstance.put(`/api/page-contents/${id}`, pageContentData)
            .then(response => {
            })
            .catch(error => {
                console.error('Error updating page content:', error);
                throw error;
            });
    },
    deletePageContent(id) {
        return axiosInstance.delete(`/api/page-contents/${id}`)
            .then(() => {
                this.pageContents = this.pageContents.filter((pageContent) => pageContent.id !== id);
            })
            .catch(error => {
                console.error('Error deleting page content:', error);
                throw error;
            });
    },
    async updateAllPageContentOrder(newOrder) {
        try {
            const updatePromises = newOrder.map((item, index) => {
                const updatedData = { ...item, order: index + 1 };
                return this.updatePageContent(item.id, updatedData);
            });

            await Promise.all(updatePromises);
            this.pageContents = [...newOrder].sort((a, b) => a.order - b.order);
        } catch (error) {
            console.error('Error updating page contents order:', error);
            throw error;
        }
    },
    // Optional: Bulk update if backend supports it
    async bulkUpdatePageContentOrder(newOrder) {
        try {
            const payload = newOrder.map((item, index) => ({
                id: item.id,
                order: index + 1,
            }));

            await axiosInstance.put(`/api/page-contents/bulk-update-order`, { items: payload });
            this.pageContents = [...newOrder].sort((a, b) => a.order - b.order);
        } catch (error) {
            console.error('Error bulk updating page contents order:', error);
            throw error;
        }
    },
};
