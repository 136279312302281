<template>
  <div class="border-[0.4px] border-[#d1d1d1] border-opacity-[26%] border-dashed p-2 rounded-lg">
    <section class="border-t-0 border-r-0 border-b-0  border-gray-400 border-l-[2px] pl-3 py-2">
      <ContentEditable
          v-model="data.value"
          :attributes="data.attributes"
          placeholder="Unesite text ovog priloga.."
          @update:modelValue="updateBlock"
          @focus="handleFocus"
      />
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ContentEditable from "@/components/library/ContentEditable.vue";

const emit = defineEmits(['editBlock']);

const props = defineProps({
  data: Object,
  id: [String, Number],
});

const text = ref('');

const updateBlock = () => {
  emit('editBlock', {id: props.id, data: props.data});
};

const handleFocus = () => {
  emit('blockFocused', props.id);
};
</script>

<style>
textarea:focus {
  outline: none;
  border: none;
  ring: 0;
}
</style>
