<template>
  <div class="h-[calc(100%-58px)] border-t-[0.24px] bg-white w-[260px] max-w-[260px]">
    <div class="flex justify-start items-center gap-2.5 px-[20px] py-[7px] border-t-0 border-r-0 border-b-[0.4px] border-l-0 border-[#e6e6e6]">
      <p class="text-[14px] text-left text-[#9f9f9f]">Typography</p>
    </div>
    <section class="p-[15px] flex flex-col gap-[10px]">
      <!-- Font Selection -->
      <div class="flex flex-col gap-[3px]">
        <p class="text-[12px] font-light text-left text-[#6d6d6d]">Font</p>
        <Dropdown
            v-model="selectedFont"
            :options="fonts"
            optionLabel="name"
            optionValue="code"
            aria-label="Font"
            placeholder="Font"
            class="w-full"
        />
      </div>
      <!-- Font Size and Spacing -->
      <div class="flex gap-[5px] w-full">
        <div class="flex flex-col flex-1 gap-[3px]">
          <p class="text-[12px] font-light text-left text-[#6d6d6d]">Size</p>
          <Dropdown
              v-model="selectedFontSize"
              :options="fontSizes"
              optionLabel="name"
              optionValue="code"
              placeholder="Normal"
          />
        </div>
        <div class="flex flex-col flex-1 gap-[3px]">
          <p class="text-[12px] font-light text-left text-[#6d6d6d]">Spacing</p>
          <Dropdown
              v-model="selectedFontSpacing"
              :options="spacingOptions"
              optionLabel="name"
              optionValue="value"
              placeholder="Spacing"
          />
        </div>
      </div>
      <!-- Font Color and Opacity -->
      <div class="flex gap-[5px] w-full">
        <div class="flex flex-col flex-1 gap-[3px]">
          <p class="text-[12px] font-light text-left text-[#6d6d6d]">Color</p>
          <div class="flex items-center h-full pl-[5px] gap-[5px] rounded-[5px] border-[0.4px] border-[#e6e6e6]">
            <ColorPicker v-model="selectedFontColor" />
            <p class="text-[12px] text-[#919191]">#{{ selectedFontColor }}</p>
          </div>
        </div>
        <div class="flex flex-col flex-1 gap-[3px]">
          <p class="text-[12px] font-light text-left text-[#6d6d6d]">Opacity</p>
          <Dropdown
              v-model="selectedFontOpacity"
              :options="opacityOptions"
              optionLabel="name"
              optionValue="value"
              placeholder="Opacity"
          />
        </div>
      </div>
      <!-- Text Alignment -->
      <div class="flex flex-col gap-[3px] w-full">
        <p class="text-[12px] font-light text-left text-[#6d6d6d]">Alignment</p>
        <SelectButton
            v-model="selectedFontAlign"
            :options="alignmentOptions"
            optionLabel="value"
            optionValue="value"
            dataKey="value"
            class="flex"
        >
          <template #option="slotProps">
            <i :class="slotProps.option.icon" class="!text-[12px]"></i>
          </template>
        </SelectButton>
      </div>
      <!-- Text Arguments -->
      <div class="flex flex-col gap-[3px] w-full">
        <p class="text-[12px] font-light text-left text-[#6d6d6d]">Arguments</p>
        <SelectButton
            v-model="selectedTextType"
            :options="textTypeOptions"
            optionLabel="value"
            optionValue="value"
            dataKey="value"
            class="flex"
        >
          <template #option="slotProps">
            <i :class="slotProps.option.icon" class="!text-[12px]"></i>
          </template>
        </SelectButton>
      </div>
    </section>
  </div>
</template>

<script setup>
import {defineProps, toRef} from 'vue';
import Dropdown from 'primevue/dropdown';
import ColorPicker from 'primevue/colorpicker';
import SelectButton from 'primevue/selectbutton';
import {useTypographyControls} from '@/components/app/dashboard/composables/useTypographyControls';
import {
  fonts,
  fontSizes,
  spacingOptions,
  opacityOptions,
  alignmentOptions,
  textTypeOptions,
} from '@/components/app/dashboard/options/typographyOptions';

// Define the props to accept from the parent component
const props = defineProps({
  activeBlock: {
    type: Object,
    required: true,
  },
  editElement: {
    type: Function,
    required: true,
  },
});

const activeBlockRef = toRef(props, 'activeBlock');

// Use the typography controls composable
const {
  selectedFont,
  selectedFontSize,
  selectedFontSpacing,
  selectedFontOpacity,
  selectedFontAlign,
  selectedTextType,
  selectedFontColor,
} = useTypographyControls(activeBlockRef, props.editElement);
</script>
