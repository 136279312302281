import axiosInstance from "@/plugins/axios";

export const actions = {
    async fetchPodcasts() {
        try {
            const response = await axiosInstance.get('/api/podcasts'); // Adjust the API endpoint as needed
            this.podcasts = response.data.data;
        } catch (error) {
            console.error("Error fetching podcasts:", error);
        }
    },
    async addPodcast(formData) {
        try {
            const response = await axiosInstance.post('/api/podcasts', formData);
            return response.data.data
        } catch (error) {
            console.error("Error adding podcast:", error);
            throw error; // Re-throw to handle it in the component if needed
        }
    },
    async deletePodcast(podcastId) {
        try {
            await axiosInstance.delete(`/api/podcasts/${podcastId}`);
            this.podcasts = this.podcasts.filter(podcast => podcast.id !== podcastId);
        } catch (error) {
            console.error("Error deleting podcast:", error);
            throw error; // Re-throw to handle it in the component if needed
        }
    },
    async uploadPodcastImage(podcastId, imageFile) {
        try {
            const formData = new FormData();
            formData.append('file', imageFile);

            await axiosInstance.post(`/api/podcasts/${podcastId}/files?media_collection=podcast_images`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            console.error('Error uploading podcast image:', error);
            throw error;
        }
    },
    async uploadPodcastMp3(podcastId, mp3File) {
        try {
            const formData = new FormData();
            formData.append('file', mp3File);

            await axiosInstance.post(`/api/podcasts/${podcastId}/files?media_collection=podcast_mp3`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            console.error('Error uploading podcast MP3:', error);
            throw error;
        }
    },
};
