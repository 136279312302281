<template>
  <div class="h-[calc(100%-58px)] border-t-[0.24px] bg-white w-[260px] max-w-[260px]">
    <header class="flex justify-start items-center gap-2.5 px-[20px] py-[7px] border-t-0 border-r-0 border-b-[0.4px] border-l-0 border-[#e6e6e6]">
      <p class="text-[14px] text-left text-[#9f9f9f]">Podcast Management</p>
    </header>
    <section class="p-4 flex flex-col gap-2 flex-grow overflow-y-auto">
      <button @click="showAddPodcastModal" class="bg-primary-500 text-white px-3 py-2 rounded">
        <span class="pr pr-add"></span> Add Podcast
      </button>
      <ul>
        <li
            v-for="podcast in podcasts"
            :key="podcast.id"
            class="flex justify-between items-center p-2 border-b"
        >
          <span>{{ podcast.title }}</span>
          <div class="flex gap-2">
            <button @click="editPodcast(podcast)" class="text-blue-500 hover:text-blue-700">
              Edit
            </button>
            <button @click="deletePodcast(podcast.id)" class="text-red-500 hover:text-red-700">
              Delete
            </button>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import AddPodcastModal from '@/components/app/dashboard/modals/AddPodcastModal.vue';
import { usePodcastStore } from '@/store/podcasts/index.js';
import {useModal} from "vue-final-modal";

const isAddPodcastModalVisible = ref(false);
const podcastStore = usePodcastStore();

// Fetch podcasts on component mount
onMounted(() => {
  podcastStore.fetchPodcasts();
});

// Computed property to get podcasts from the store
const podcasts = computed(() => podcastStore.podcasts);

// Function to show the add podcast modal
const showAddPodcastModal = () => {
  const { open, close } = useModal({
    component: AddPodcastModal,
    attrs: {
      data: {
      },
      onConfirm(data) {
        podcastStore.fetchPodcasts()
        close()
      }
    }
  })
  open()
};

// Handle modal confirmation (if any)
const handleAddPodcastConfirm = () => {
  // Implement confirmation logic if needed
};

// Handle after a podcast is created
const handleAddPodcastCreated = () => {
  isAddPodcastModalVisible.value = false;
  podcastStore.fetchPodcasts(); // Refresh the list
};

// Edit podcast function (implementation depends on your requirements)
const editPodcast = (podcast) => {
  // Implement edit functionality, possibly opening another modal
  alert(`Edit functionality for "${podcast.title}" is not implemented yet.`);
};

// Delete podcast function
const deletePodcast = (podcastId) => {
  if (confirm('Are you sure you want to delete this podcast?')) {
    podcastStore.deletePodcast(podcastId)
        .then(() => {
          podcastStore.fetchPodcasts(); // Refresh the list
        })
        .catch(error => {
          console.error("Error deleting podcast:", error);
          alert("Failed to delete the podcast. Please try again.");
        });
  }
};
</script>

<style scoped>
/* Add any specific styles if needed */
</style>
