<template>
    <div class="w-full md:flex flex-col gap-1 pb-4 font-[300] text-center md:text-left text-white hidden border-b border-opacity-[25%] border-[#90FCFF]">
        <div class="w-full flex md:flex-row flex-col justify-between items-center">
            <h1 class="text-[14px] xl:text-[12px] 4xl:text-[28px]">{{ title }}</h1>
            <p class="text-[10px] xl:text-[8px] 4xl:text-[22px] opacity-[49%]">{{ timeanddate }}</p>
        </div>
        <p class="w-full text-[12px]  xl:text-[10px] 4xl:text-[24px] opacity-[66%]">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String,
        timeanddate: String
    }
}
</script>
