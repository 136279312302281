<template>
    <reset-page />
  </template>
  
  <script>

    import ResetPage from "../components/app/user/ResetPage.vue"
  
  export default {
    components: {
      ResetPage
    }
  }
  </script>
  
  <style scoped>
  </style>