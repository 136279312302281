import { ref, computed } from 'vue';
import moment from 'moment';
import { usePrayTimeStore } from '@/store/pray-times/index.js';

export function usePrayerTimes() {
    const prayTimeStore = usePrayTimeStore();
    const countdownData = ref({
        sunrise: [0, 100],
        fajr: [0, 100],
        dhuhr: [0, 100],
        asr: [0, 100],
        maghrib: [0, 100],
        isha: [0, 100],
    });

    const currentPrayerCountdown = computed(() => {
        const now = moment();
        const prayers = ['fajr', 'sunrise', 'dhuhr', 'asr', 'maghrib', 'isha'];
        let nextPrayerTime = null;
        let nextPrayerName = '';

        for (let i = 0; i < prayers.length; i++) {
            const prayerTime = moment(prayTimeStore.prayTimes[prayers[i]], 'HH:mm');
            if (now.isBefore(prayerTime)) {
                nextPrayerTime = prayerTime;
                nextPrayerName = prayers[i];
                break;
            }
        }

        if (!nextPrayerTime) {
            nextPrayerTime = moment(prayTimeStore.prayTimesTomorrow?.fajr, 'HH:mm').add(1, 'days');
            nextPrayerName = 'fajr';
        }

        const duration = moment.duration(nextPrayerTime.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();

        return {
            name: nextPrayerName,
            countdown: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        };
    });

    function updateCountdownData() {
        const now = moment();
        const prayers = ['fajr', 'sunrise', 'dhuhr', 'asr', 'maghrib', 'isha'];
        let firstUpcomingPrayerFound = false;
        const tomorrowFajr = prayTimeStore.prayTimesTomorrow?.fajr;
        const newCountdownData = {};

        prayers.forEach((prayer) => {
            let prayerTime = prayTimeStore.prayTimes[prayer];
            let timeLeft;

            if (!firstUpcomingPrayerFound) {
                if (prayer === 'isha' && tomorrowFajr) {
                    timeLeft = calculateTimeLeftPercentageForIshaToNextFajr(prayerTime, tomorrowFajr);
                } else {
                    timeLeft = calculateTimeLeftPercentage(prayerTime);
                }

                newCountdownData[prayer] = timeLeft;

                if (timeLeft[0] < 100) {
                    firstUpcomingPrayerFound = true;
                }
            } else {
                newCountdownData[prayer] = [0, 100];
            }
        });

        countdownData.value = { ...newCountdownData };
    }

    function calculateTimeLeftPercentageForIshaToNextFajr(ishaTime, nextDayFajrTime) {
        const now = moment();
        const ishaMoment = moment(ishaTime, 'HH:mm');
        const nextFajrMoment = moment(nextDayFajrTime, 'HH:mm').add(1, 'days');

        if (!ishaMoment.isValid() || !nextFajrMoment.isValid()) {
            return [0, 100];
        }

        if (now.isAfter(ishaMoment)) {
            const totalMinutes = nextFajrMoment.diff(now, 'minutes');
            const percentageLeft = totalMinutes > 0 ? (totalMinutes / ((24 * 60) - ishaMoment.diff(now, 'minutes'))) * 100 : 0;
            return [100 - percentageLeft, percentageLeft];
        }

        return calculateTimeLeftPercentage(ishaTime);
    }

    function calculateTimeLeftPercentage(prayerTime) {
        const nextPrayerTime = moment(prayerTime, 'HH:mm');
        const now = moment();

        if (!nextPrayerTime.isValid()) {
            return [0, 100];
        }

        if (now.isAfter(nextPrayerTime)) {
            return [100, 0];
        }

        const totalMinutes = nextPrayerTime.diff(now, 'minutes');
        const percentageLeft = totalMinutes > 0 ? (totalMinutes / 1440) * 100 : 0;

        return [100 - percentageLeft, percentageLeft];
    }

    const fetchPrayerTimes = async () => {
        await prayTimeStore.getPrayTimes(moment().format('M'), moment().format('D'));
        await prayTimeStore.getTomorrowPrayTimes(moment().format('M'), moment().add(1, 'days').format('D'));
        updateCountdownData();
    };

    return {
        countdownData,
        currentPrayerCountdown,
        fetchPrayerTimes,
        updateCountdownData,
    };
}
