export function useTextFormatting(editable, selectedRange, updateContent, emit) {
    const applyFormat = (format) => {
        if (!selectedRange.value) {
            console.log('No selected range found.');
            return;
        }

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(selectedRange.value);

        if (editable.value.contains(selection.anchorNode)) {
            const wrapperTag = getWrapperTag(format);

            if (!wrapperTag) {
                console.log('No wrapper tag found for format:', format);
                return;
            }

            const range = selection.getRangeAt(0);
            console.log('Applying format:', wrapperTag, 'to range:', range);

            // Check if the selected text already has the format applied
            const isApplied = isTagApplied(range, wrapperTag);
            console.log('Is tag applied:', isApplied);

            if (isApplied) {
                console.log('Removing format:', wrapperTag);
                removeFormatFromRange(range, wrapperTag);
            } else {
                console.log('Applying format:', wrapperTag);
                wrapRangeWithFormat(range, wrapperTag);
            }

            // Update content and emit change
            updateContent();
            emit('update:modelValue', editable.value.innerHTML);

            // Restore selection position after formatting
            selection.removeAllRanges();
            selection.addRange(selectedRange.value);
        }
    };

    const getWrapperTag = (format) => {
        switch (format) {
            case 'bold':
                return 'b';
            case 'italic':
                return 'i';
            case 'underline':
                return 'u';
            default:
                return null;
        }
    };

    const isTagApplied = (range, wrapperTag) => {
        const ancestor = range.commonAncestorContainer;
        let currentNode = ancestor.nodeType === 1 ? ancestor : ancestor.parentNode;
        while (currentNode && currentNode !== editable.value) {
            console.log('Checking node:', currentNode);
            if (currentNode.tagName && currentNode.tagName.toLowerCase() === wrapperTag) {
                console.log('Tag found in node:', currentNode);
                return true; // Tag is applied
            }
            currentNode = currentNode.parentNode;
        }
        console.log('Tag not found.');
        return false; // Tag is not applied
    };

    const wrapRangeWithFormat = (range, wrapperTag) => {
        const wrapper = document.createElement(wrapperTag);
        wrapper.appendChild(range.extractContents());
        range.insertNode(wrapper);
        console.log('Wrapped range with tag:', wrapperTag);
    };

    const removeFormatFromRange = (range, wrapperTag) => {
        const ancestor = range.commonAncestorContainer;
        let currentNode = ancestor.nodeType === 1 ? ancestor : ancestor.parentNode;

        // Traverse up and unwrap the tag if found
        while (currentNode && currentNode !== editable.value) {
            if (currentNode.tagName && currentNode.tagName.toLowerCase() === wrapperTag) {
                unwrapElement(currentNode);
                return;
            }
            currentNode = currentNode.parentNode;
        }

        console.log('No tag found in the range to remove.');
    };

    const unwrapElement = (element) => {
        const parent = element.parentNode;
        while (element.firstChild) {
            parent.insertBefore(element.firstChild, element);
        }
        parent.removeChild(element);
        console.log('Unwrapped element:', element);
    };

    return {
        applyFormat,
    };
}
