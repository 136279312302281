/**
 * Composable to handle saving and restoring cursor positions.
 * @returns {Object} - Functions to save and restore cursor positions.
 */
export function useCursorPosition() {
    /**
     * Save the current cursor position within the editable element.
     * @param {HTMLElement} editableElement - The editable div.
     * @returns {Object|null} - The saved cursor position or null.
     */
    const saveCursorPosition = (editableElement) => {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return null;
        const range = selection.getRangeAt(0);
        const preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(editableElement);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        return { start, end: start + range.toString().length };
    };

    /**
     * Restore the cursor position within the editable element.
     * @param {HTMLElement} editableElement - The editable div.
     * @param {Object} savedPosition - The saved cursor position.
     */
    const restoreCursorPosition = (editableElement, savedPosition) => {
        if (!savedPosition) return;

        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(editableElement, 0);
        range.collapse(true);

        let charIndex = 0;
        const nodeStack = [editableElement];
        let node;
        let foundStart = false;
        let stop = false;

        while (!stop && (node = nodeStack.pop())) {
            if (node.nodeType === 3) { // Text node
                const nextCharIndex = charIndex + node.length;
                if (!foundStart && savedPosition.start >= charIndex && savedPosition.start <= nextCharIndex) {
                    range.setStart(node, savedPosition.start - charIndex);
                    foundStart = true;
                }
                if (foundStart && savedPosition.end >= charIndex && savedPosition.end <= nextCharIndex) {
                    range.setEnd(node, savedPosition.end - charIndex);
                    stop = true;
                }
                charIndex = nextCharIndex;
            } else { // Element node
                let i = node.childNodes.length;
                while (i--) {
                    nodeStack.push(node.childNodes[i]);
                }
            }
        }

        selection.removeAllRanges();
        selection.addRange(range);
    };

    return {
        saveCursorPosition,
        restoreCursorPosition,
    };
}
