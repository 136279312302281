<template>
  <div class="h-2/3">
    <Bar :data="data" :options="options" />
  </div>
</template>

<script setup>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { ref, watch } from "vue";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Maj',
        'Jun',
        'Jul',
        'Avg',
        'Sep',
        'Okt',
        'Nov',
        'Dec'
      ],
      datasets: [
        {
          backgroundColor: '#247C6E',
          borderRadius: 6,
          data: [5, 5, 12, 20, 5, 25, 5, 15, 20, 30, 40, 5]
        }
      ]
    })
  }
})

const data = ref(props.data)

watch(() => props.data, (newData) => {
  data.value = newData;
}, { deep: true });

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      display: false,
      grid: {
        display: false
      },
      ticks: {
        display: false
      }
    },
    x: {
      grid: {
        display: false
      }
    },
  },
  plugins: {
    legend: {
      display: false
    }
  }
}
</script>
