import { computed } from 'vue';

export function useComputedStyles(attributes) {
    const computedStyle = computed(() => {
        const colorValue = attributes?.color || '#000000';
        const opacity = attributes?.opacity !== undefined ? attributes.opacity : 1.0;
        const rgbaColor = hexToRgba(colorValue, opacity);

        let fontWeight = 'normal';

        console.log(attributes?.textType)

        if (attributes?.textType === 'bold') {
            fontWeight = 'bold';
        } else if (attributes?.textType === 'italic') {
            fontWeight = 'italic';
        } else if (attributes?.textType === 'underline') {
            fontWeight = 'underline';
        }

        const styles = {
            color: rgbaColor,
            fontWeight: fontWeight || 'normal',
            fontStyle: attributes?.fontStyle || 'normal',
            textDecoration: attributes?.textDecoration || 'none',
            textAlign: attributes?.align || 'left',
            fontFamily: attributes?.font || 'inherit',
            fontSize: attributes?.fontSize ? attributes.fontSize + 'px' : 'inherit',
            wordSpacing: attributes?.spacing ? attributes.spacing + 'px' : 'normal',
        };

        return styles;
    });

    const computedPlaceholderColor = computed(() => {
        return attributes?.color || 'rgba(255, 255, 255, 0.56)';
    });

    const hexToRgba = (hex, opacity) => {
        hex = hex.replace('#', '');

        if (hex.length === 3) {
            hex = hex.split('').map((char) => char + char).join('');
        }

        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    return {
        computedStyle,
        computedPlaceholderColor,
    };
}
