import { ref, computed } from 'vue';
import { useDebounceFn } from '@vueuse/core';

export function useTypographyControls(activeBlock, editElement) {
    // Function to update the active block (debounced)
    const updateActiveBlock = useDebounceFn(() => {
        if (activeBlock.value) {
            editElement(activeBlock.value);
        }
    }, 300);

    // Helper function to safely access nested properties
    const getAttribute = (attribute, defaultValue = null) => {
        return activeBlock?.value?.content?.attributes?.[attribute] ?? defaultValue;
    };

    const setAttribute = (attribute, value) => {
        if (activeBlock.value && activeBlock.value.content && activeBlock.value.content.attributes) {
            activeBlock.value.content.attributes[attribute] = value;
            updateActiveBlock();
        }
    };

    // Computed Properties
    const selectedFont = computed({
        get() {
            return getAttribute('font');
        },
        set(value) {
            setAttribute('font', value);
        },
    });

    const selectedFontSize = computed({
        get() {
            return getAttribute('fontSize');
        },
        set(value) {
            setAttribute('fontSize', value);
        },
    });

    const selectedFontSpacing = computed({
        get() {
            return getAttribute('spacing', 'normal');
        },
        set(value) {
            setAttribute('spacing', value);
        },
    });

    const selectedFontOpacity = computed({
        get() {
            return getAttribute('opacity', 1.0);
        },
        set(value) {
            setAttribute('opacity', value);
        },
    });

    const selectedFontAlign = computed({
        get() {
            return getAttribute('align', 'left');
        },
        set(value) {
            setAttribute('align', value);
        },
    });

    const selectedTextType = computed({
        get() {
            return getAttribute('textType', 'text');
        },
        set(value) {
            setAttribute('textType', value);
        },
    });

    const selectedFontColor = computed({
        get() {
            return getAttribute('color', '#000000');
        },
        set(value) {
            setAttribute('color', value);
        },
    });

    return {
        selectedFont,
        selectedFontSize,
        selectedFontSpacing,
        selectedFontOpacity,
        selectedFontAlign,
        selectedTextType,
        selectedFontColor,
    };
}
