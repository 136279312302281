import axiosInstance from "@/plugins/axios";

export const actions = {
    async getTotals(type, name) {
        try {
            const response = await axiosInstance.get(`/api/totals?type=${type}&name=${name}`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pages:', error);
            throw error;
        }
    },
};
