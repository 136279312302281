<template>
  <section class="w-full px-20 py-4 flex sm:items-center sm:justify-center lg:items-end lg:justify-end">
    <div class="w-1/2 4xl:w-full overflow-visible flex gap-10 xl:gap-5 4xl:gap-20 flex-col max-w-6xl items-center 4xl:mt-[100px]">
      <h1 class="dark:text-[#FFB8A2] 4xl:text-[80px] 2xl:text-[64px] xl:text-[44px] lg:text-4xl text-[32px] text-center text-[#ECD79F]">{{ title }}</h1>
      <section class="w-full flex items-center justify-center">
        <CircularChart
            v-for="prayer in ['fajr', 'sunrise', 'dhuhr', 'asr', 'maghrib', 'isha']"
            :key="prayer"
            :title="prayTimeNames[prayer]"
            :subtitle="prayer === currentPrayerCountdown.name ? currentPrayerCountdown.countdown : (prayTimeStore.prayTimes[prayer] ? prayTimeStore.prayTimes[prayer] : '---')"
            :data="countdownData[prayer]"
        />
      </section>
      <section class="flex flex-col gap-4 xl:gap-2">
        <hero-notes
            title="Za postače samo nagrada"
            timeanddate="11.03.2024 22:00"
            subtitle="Ebu Hurejre, r.a., prenosi od Allahovog Poslanika, s.a.v.s., da je rekao: „Ko posti Ramazan vjerujući i nadajući se nagradi, bit će mu oprošteni grijesi koje je prethodno počinio!“">
        </hero-notes>
        <hero-notes
            title="Džennetska vrata otvorena, džehennemska zatvorena"
            timeanddate="11.03.2024 22:10"
            subtitle="Ebu Hurejre, r.a., također, prenosi od Allahovog Poslanika, s.a.v.s.: „Kada nastupi Ramazan, otvore se džennetska, zatvore džehennemska vrata a šejtani se povežu!“">
        </hero-notes>
        <hero-notes
            title="Nagrada za postače"
            timeanddate="11.03.2024 22:13"
            subtitle="Ebu Se’id el-Hudri, r.a., prenosi da je Allahov Poslanik, s.a.v.s., rekao: „Allah će sedamdeset godina udaljiti vatru od osobe koja posti jedan dan na Allahovom putu!“">
        </hero-notes>
      </section>
      <app-store-button image="apple-icon.svg" class="z-10" />
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import AppStoreButton from '@/components/buttons/AppStoreButton.vue';
import HeroNotes from './HeroNotes.vue';
import CircularChart from '@/components/app/charttime/CircularChart.vue';
import { usePrayTimeStore } from '@/store/pray-times/index.js';
import { usePrayerTimes } from './usePrayerTimes';

const prayTimeStore = usePrayTimeStore();

const props = defineProps({
  title: String,
});

const prayTimeNames = {
  fajr: 'Zora',
  sunrise: 'Izlazak Sunca',
  dhuhr: 'Podne',
  asr: 'Ikindija',
  maghrib: 'Akšam',
  isha: 'Jacija',
}

const { countdownData, currentPrayerCountdown, fetchPrayerTimes, updateCountdownData } = usePrayerTimes();

onMounted(async () => {
  await fetchPrayerTimes();
});

useIntervalFn(updateCountdownData, 60000, { immediate: true });

</script>

<style scoped>
h1 {
  font-family: "Ramadanish";
}
</style>
