<template>
  <login />
</template>

<script>
import Login from "@/components/app/user/Login.vue"

export default {
  components: {
    Login
  }
}
</script>

<style scoped>
</style>
