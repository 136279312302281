import Home from '@/pages/Home.vue';

export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { layout: 'App' }
    },
];
