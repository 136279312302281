<template>
  <div
      class="toolbar"
      :style="{ top: position.y + 'px', left: position.x + 'px' }"
  >
    <button @mousedown.prevent @click="handleClick('bold')">
      <span class="pr pr-text-bold"></span>
    </button>
    <button @mousedown.prevent @click="handleClick('italic')">
      <span class="pr pr-text-italic"></span>
    </button>
    <button @mousedown.prevent @click="handleClick('underline')">
      <i class="pr pr-text-underline"></i>
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['apply-format']);

const props = defineProps({
  position: Object,
});

const handleClick = (format) => {
  emit('apply-format', format);
};
</script>

<style scoped>
.toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  z-index: 10000;
}

.toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
</style>
