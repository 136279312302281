export const fonts = [
    { name: 'Roboto', code: 'Roboto, sans-serif' },
    { name: 'Times New Roman', code: '"Times New Roman", Times, serif' },
    { name: 'Arial', code: 'Arial, Helvetica, sans-serif' },
];

export const fontSizes = [
    { name: '8px', code: '8' },
    { name: '9px', code: '9' },
    { name: '10px', code: '10' },
    { name: '11px', code: '11' },
    { name: '12px', code: '12' },
    { name: '14px', code: '14' },
    { name: '16px', code: '16' },
    { name: '18px', code: '18' },
    { name: '20px', code: '20' },
    { name: '24px', code: '24' },
];

export const spacingOptions = [
    { name: 'Normal', value: 'normal' },
    { name: '1px', value: '1' },
    { name: '2px', value: '2' },
    { name: '3px', value: '3' },
    { name: '4px', value: '4' },
    { name: '5px', value: '5' },
    { name: '6px', value: '6' },
    { name: '7px', value: '7' },
    { name: '8px', value: '8' },
    { name: '9px', value: '9' },
    { name: '10px', value: '10' },
];

export const opacityOptions = [
    { name: '10%', value: 0.1 },
    { name: '20%', value: 0.2 },
    { name: '30%', value: 0.3 },
    { name: '40%', value: 0.4 },
    { name: '50%', value: 0.5 },
    { name: '60%', value: 0.6 },
    { name: '70%', value: 0.7 },
    { name: '80%', value: 0.8 },
    { name: '90%', value: 0.9 },
    { name: '100%', value: 1.0 },
];

export const alignmentOptions = [
    { icon: 'pr pr-textalign-left', value: 'left' },
    { icon: 'pr pr-textalign-center', value: 'center' },
    { icon: 'pr pr-textalign-right', value: 'right' },
    { icon: 'pr pr-textalign-justifycenter', value: 'justify' },
];

export const textTypeOptions = [
    { icon: 'pr pr-text-bold', value: 'bold' },
    { icon: 'pr pr-text-italic', value: 'italic' },
    { icon: 'pr pr-text-underline', value: 'underline' },
    { icon: 'pr pr-text', value: 'text' },
];
