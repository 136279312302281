<template>
  <div class="flex overflow-y-hidden">
    <SideBar />
    <ExtendedSideBar />
    <div class="flex-grow h-screen flex flex-col">
      <TopBar />
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useBooksStore} from "@/store/books/index.js";
import ExtendedSideBar from "@/components/app/library/ExtendedSideBar.vue";
import SideBar from "@/components/app/dashboard/SideBar.vue";
import TopBar from "@/components/app/dashboard/TopBar.vue";

const booksStore = useBooksStore()

onMounted(() => {
  booksStore.fetchBooks();
});


</script>
