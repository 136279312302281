<template>
  <div class="w-full flex-grow flex flex-col justify-between h-[400px] bg-white rounded-[10px] mb-6 border-[0.3px] border-[#CFCFCF] pb-5 px-10">
    <div class="w-full flex justify-between">
      <div class="pt-10 flex flex-col justify-center items-start">
        <h3 class="text-[#3E546C] text-[16px]">Ukupno korisnika u odredjenom periodu</h3>
        <p class="text-[#3E546C] text-[18px] font-semibold">{{ totalSum }}</p>
      </div>

      <div class="h-full flex justify-center items-center">
        <Dropdown align="left" width="130px">
          <button class="border-[0.4px] border-gray text-gray-500 px-5 py-0.5 rounded-xl flex justify-center items-center">{{dateType.name}} <span class="pr pr-arrow-bottom-87we ml-3"></span></button>
          <template #items>
            <DropdownItemGroup>
              <DropdownItem v-for="dateType in dateTypes" @click="loadData(dateType)" as="button">{{dateType.name}}</DropdownItem>
            </DropdownItemGroup>
          </template>
        </Dropdown>
      </div>
    </div>

    <StatisticAppVue :data="chartData" />
  </div>
</template>

<script setup>
import StatisticAppVue from '@/components/app/graphbar/StatisticApp.vue'
import {computed, onMounted, ref} from "vue";
import { usePrayTimeStore } from '@/store/pray-times/index.js';
import Dropdown from "@/components/app/dropdown/Dropdown.vue";
import DropdownItemGroup from "@/components/app/dropdown/DropdownItemGroup.vue";
import DropdownItem from "@/components/app/dropdown/DropdownItem.vue";

const prayTimeStore = usePrayTimeStore();
const labels = ref([]);
const dataset = ref([]);
const dateType = ref({
  type: 'month',
  name: 'Mesec',
});

const chartData = ref({
  labels: [],
  datasets: [
    {
      backgroundColor: '#247C6E',
      borderRadius: 6,
      data: []
    }
  ]
});

const dateTypes = [
  {
    type: 'day',
    name: 'Dan',
  },
  {
    type: 'month',
    name: 'Mesec',
  },
  {
    type: 'year',
    name: 'Godina',
  }
];

const loadData = (dateType) => {
  fetchData(dateType);
}

const fetchData = async (statisticsPeriod) => {
  let startDate = '2024-01-01';
  let endDate = '2024-12-01';
  dateType.value = statisticsPeriod;

  const response = await prayTimeStore.getPrayTimeTotalAnalytics(startDate, endDate, statisticsPeriod.type);
  labels.value = response.map(item => item.period);
  dataset.value = response.map(item => item.total);

  chartData.value = {
    labels: labels.value,
    datasets: [
      {
        backgroundColor: '#247C6E',
        borderRadius: 6,
        data: dataset.value
      }
    ]
  };
};

const totalSum = computed(() => {
  return dataset.value.reduce((acc, val) => acc + val, 0);
});

onMounted(() => {
  fetchData(dateType.value);
});
</script>
