<template>
  <div class="w-full editable-container relative" @click="focusEditable">
    <div
        ref="editable"
        contenteditable="true"
        class="editable w-full min-h-[20px] outline-none bg-transparent"
        :class="{ 'placeholder-shown': !content.trim() }"
        :data-placeholder="placeholder"
        :style="computedStyle"
        @input="handleInput"
        @keydown="handleKeyDown"
        @focus="handleFocus"
        @blur="handleBlur"
        @mouseup="handleSelection"
        @keyup="handleSelection"
        @paste="handlePaste"
    ></div>
    <FormattingToolbar
        v-if="showToolbar"
        :position="toolbarPosition"
        @apply-format="applyFormat"
    />
  </div>
</template>

<script setup>
import {ref, watch, computed, onMounted} from 'vue';
import {useDebounceFn} from '@vueuse/core';
import FormattingToolbar from '@/components/app/dashboard/FormattingToolbar.vue';
import {useTextFormatting} from '@/composables/useTextFormatting';
import {useComputedStyles} from '@/composables/useComputedStyles';
import {useCursorPosition} from '@/composables/useCursorPosition'; // Import cursor position logic

const emit = defineEmits(['update:modelValue', 'focus']);
const props = defineProps({
  modelValue: String,
  placeholder: String,
  attributes: Object,
});

const editable = ref(null);
const content = ref(props.modelValue || '');
const showToolbar = ref(false);
const toolbarPosition = ref({x: 0, y: 0});
const selectedRange = ref(null);

const {saveCursorPosition, restoreCursorPosition} = useCursorPosition();
let savedCursorPosition = null; // Variable to store cursor position

const autosave = useDebounceFn(() => {
  emit('update:modelValue', content.value);
}, 3000);

const updateContent = () => {
  content.value = editable.value.innerHTML;
  autosave();
};

const {applyFormat} = useTextFormatting(editable, selectedRange, updateContent, emit);
const {computedStyle, computedPlaceholderColor} = useComputedStyles(props.attributes);

const focusEditable = () => {
  editable.value.focus();
};

// Handle saving and restoring cursor position during input
const handleInput = () => {
  savedCursorPosition = saveCursorPosition(editable.value); // Save cursor position before sending content
  updateContent();
};

const handleSelection = () => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    if (editable.value.contains(range.commonAncestorContainer)) {
      if (!range.collapsed) {
        showToolbar.value = true;
        selectedRange.value = range.cloneRange();

        // Calculate toolbar position
        const rect = range.getBoundingClientRect();
        const containerRect = editable.value.getBoundingClientRect();

        toolbarPosition.value = {
          x: rect.left + rect.width / 2 - containerRect.left,
          y: rect.top - containerRect.top - 50,
        };
      } else {
        showToolbar.value = false;
        selectedRange.value = null;
      }
    } else {
      showToolbar.value = false;
      selectedRange.value = null;
    }
  } else {
    showToolbar.value = false;
    selectedRange.value = null;
  }
};

const handleBlur = () => {
  showToolbar.value = false;
};

onMounted(() => {
  if (editable.value) {
    editable.value.innerHTML = content.value;
  }
});

watch(
    () => props.modelValue,
    (newValue) => {
      content.value = newValue || '';
      if (editable.value) {
        editable.value.innerHTML = content.value;
        restoreCursorPosition(editable.value, savedCursorPosition); // Restore cursor after content is updated
      }
    }
);

const handlePaste = (event) => {
  event.preventDefault();
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData('text/plain');
  document.execCommand('insertText', false, pastedText);
  updateContent();
};

const handleFocus = () => {
  emit('focus');
};
</script>

<style scoped>
.editable-container .editable.placeholder-shown::before {
  content: attr(data-placeholder);
  color: var(--placeholder-color);
  pointer-events: none;
}

.editable:focus {
  outline: none;
}
</style>
