<template>
  <div class="border-[0.4px] border-[#d1d1d1] border-opacity-[26%] border-dashed p-2 rounded-lg relative">
    <DropZone
        v-model="data.value"
        :uploadId="id"
        @upload-error="handleUploadError"
        class="w-full h-full"
    />
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import DropZone from '@/components/app/Dropzone.vue'; // Adjust the path as needed

const emit = defineEmits(['editBlock', 'blockFocused']);

const props = defineProps({
  data: Object,
  id: [String, Number],
});

// Watch for changes and emit updates
watch(() => props.data.value, (newValue, oldValue) => {
  emit('editBlock', { id: props.id, data: props.data });
});

// Handle upload errors if needed
const handleUploadError = (error) => {
  console.error('Image upload error in ImageBlock:', error);
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
