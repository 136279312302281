import {useRoute} from "vue-router";

export function useHandleSelection(pageContentsStore) {
    const route = useRoute();
    const handleSelection = (selectedItem) => {
        let item = {};

        switch (selectedItem.type) {
            case 'text':
                item = {
                    type: selectedItem.type,
                    page_id: route.params.pageId,
                    content: {
                        value: '',
                        attributes: {
                            align: "justify",
                            color: "808080",
                            font: "Arial, Helvetica, sans-serif;",
                            fontSize: "12",
                            opacity: 1,
                            spacing: "normal",
                            textType: "text"
                        }
                    }
                };
                break;
            case 'quote':
                item = {
                    type: selectedItem.type,
                    page_id: route.params.pageId,
                    content: {
                        value: '',
                        attributes: {
                            align: "justify",
                            color: "585858",
                            font: "Arial, Helvetica, sans-serif;",
                            fontSize: "16",
                            opacity: 1,
                            spacing: "normal",
                            textType: "text"
                        }
                    }
                };
                break;
            case 'image':
                item = {
                    type: selectedItem.type,
                    page_id: route.params.pageId,
                    content: {
                        value: '',
                        attributes: {
                            color: '#000000',
                        }
                    }
                };
                break;
        }

        pageContentsStore.putNewItem(item);
    };

    return { handleSelection };
}
