<template> 
    <new-password />
</template>

<script>
import NewPassword from '@/components/app/user/NewPassword.vue';
export default {
    components: {
        NewPassword
    }
}
</script>
