<template>
  <div class="flex overflow-y-hidden">
    <Sidebar />
    <div class="flex-grow h-screen flex flex-col">
      <TopBar />
      <section class="w-full h-[calc(100%-58px)] flex flex-col bg-[#FAFAFA]">
        <SummaryBar class="px-[20px]"/>
        <section class="w-full h-[calc(100%-115px)] flex flex-grow px-[20px] pb-[20px] pt-[10px] space-x-[20px] overflow-x-scroll">
          <BookCard v-for="book in booksStore.books" :title="book.name"/>
        </section>
      </section>
    </div>
  </div>
</template>

<script setup>
import TopBar from '@/components/app/dashboard/TopBar.vue';
import SummaryBar from '@/components/app/dashboard/SummaryBar.vue';
import {onMounted, ref} from "vue";
import {useBooksStore} from "@/store/books/index.js";
import BookCard from "@/components/BookCard.vue";

const booksStore = useBooksStore()

onMounted(() => {
  booksStore.fetchBooks();
});


</script>
